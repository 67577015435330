import { trendArrowImages } from '../../../utils/dashboardRenderer';
import { format } from 'date-fns';
const moment = require('moment');

const _ = require('lodash');
const startOfWeek = require('date-fns/start_of_week');
const startOfMonth = require('date-fns/start_of_month');
const startOfQuarter = require('date-fns/start_of_quarter');
const endOfWeek = require('date-fns/end_of_week');
const endOfMonth = require('date-fns/end_of_month');
const endOfQuarter = require('date-fns/end_of_quarter');
const getMonth = require('date-fns/get_month');

const lesserThanOne = "<1";
const typeNumber = "number";

const clientFunctions = {
    cleaningCycles: {
        transformResponse: ({ _clientResponse }) => {

            let lastDate = _clientResponse?.data?.data?.previous?.[0]?.deviceTimestamp ? moment(_clientResponse.data.data.previous[0].deviceTimestamp).format("MMMM D,YYYY HH:mm A") : "NA"
            return {
                current: _clientResponse.data.data.current,
                previous: lastDate
            }
        }
    },
    avgProductCount: {
        transformResponse: ({ _clientResponse, _widgetScope }) => {
            const current = _clientResponse?.data?.data?.current ? _clientResponse.data.data.current : 0;
            const previous = _clientResponse?.data?.data?.previous ? _clientResponse.data.data.previous : 0;
            const days = _widgetScope.dateFilterDays

            let currentResult = days > 0 ? Math.round(Number(current / days)) : 0
            let prevResult = days > 0 ? Math.round(Number(previous / days)) : 0

            return {
                current: currentResult < 1 ? '<1' : currentResult,
                previous: prevResult < 1 ? '<1' : prevResult
            }
        }
    },
    peakHour: {
        transformResponse: ({ _clientResponse, _widgetScope }) => {
            let productData = _clientResponse?.data?.data?.productData || [];

            if (productData.length === 0) {
                return { chartData: { data: [], subTitleMessage: "0", setMaxPrecision: false } };
            }

            let totalCooks = 0, average = 0;
            const is24Hours = localStorage.getItem("timeFormat") === "24";
            const days = moment().daysInMonth();
            const daysInMonth = Array.from({ length: days }, (_, i) => { return i + 1 });
            const hours24 = Array.from({ length: 24 }, (_, i) => { return `${i}-${i + 1}` });
            const hours12 = Array.from({ length: 24 }, (_, i) => { return (i > 12) ? `${i - 12}-${i - 11} pm` : (i === 12) ? `12-1 pm` : `${i}-${i + 1} am` });
            const months = Array.from({ length: 12 }, (_, i) => { return moment().month(i).format('MMMM') });

            const xAxis = {
                hourly24: hours24,
                hourly12: hours12,
                Year: months,
                Quarter: months,
                Month: daysInMonth,
                Week: daysInMonth
            }

            totalCooks = _.sumBy(productData, 'cooks');

            //Get average cooks per day
            if (typeof (totalCooks) === typeNumber) {
                average = totalCooks / _widgetScope.dateFilterDays;
                average = average > 0 && average < 1 ? lesserThanOne : average.toFixed(0, 2);
            }

            if (_widgetScope.dateFilterValue.value !== "Day") {
                let dateFormat = (_widgetScope.dateFilterValue.value === "Week" || _widgetScope.dateFilterValue.value === "Month" || _widgetScope.dateFilterValue.value === "Custom") ? "D" : (_widgetScope.dateFilterValue.value === "Year" || _widgetScope.dateFilterValue.value === "Quarter") ? "MMMM" : "";
                productData = productData.map(item => {
                    let day = item.key.split('-')[2] || 1; //Setting default date to 1 for quarter and year type filter
                    let month = item.key.split('-')[1];
                    day = day && day.length === 1 ? '0' + day : day;
                    month = month && month.length === 1 ? '0' + month : month;
                    let date = item.key.split('-')[0] + '-' + month + '-' + day;
                    item.key = (moment(date).format(dateFormat));
                    return {
                        "x": item.key,
                        "y": item.cooks,
                        "alpha": 1
                    };
                });

            } else {
                if (is24Hours) {
                    productData = productData.map(item => {
                        item.key = item.key + 1;
                        item.key = (item.key === 24 ? "23-24" : `${item.key - 1}-${item.key}`);
                        return {
                            "x": item.key,
                            "y": item.cooks,
                            "alpha": 1
                        };
                    });
                } else {
                    productData = productData.map(item => {
                        item.key = item.key + 1;
                        item.key = (item.key === 12 ? "11-12 am" : item.key === 24 ? "11-12 pm" : item.key > 12 ? item.key === 13 ? "12-1 pm" : `${item.key - 13}-${item.key - 12} pm` : `${item.key - 1}-${item.key} am`);
                        item.alpha = 1;
                        return {
                            "x": item.key,
                            "y": item.cooks,
                            "alpha": 1
                        };
                    });
                }
            }

            let dataSet = [];
            if (_widgetScope.dateFilterValue.value === "Day") {
                let baseX = is24Hours ? xAxis.hourly24 : xAxis.hourly12;
                dataSet = baseX.map(item => {
                    return {
                        x: item,
                        y: productData.find(res => res.x == item)?.y || 0,
                        alpha: 1
                    }
                })
            } else {
                dataSet = xAxis[_widgetScope.dateFilterValue.value].map(item => {
                    return {
                        x: item,
                        y: productData.find(res => res.x == item)?.y || 0,
                        alpha: 1
                    }
                });
            }


            const todaysDate = new Date();
            let categoryStarts = null;
            let categoryEnds = null;
            if (_widgetScope.dateFilterValue.value === "Week") {
                let startDateMonth = getMonth(startOfWeek(todaysDate, { weekStartsOn: 1 }));
                let endDateMonth = getMonth(endOfWeek(todaysDate, { weekStartsOn: 1 }));
                let todayMonth = getMonth(todaysDate);

                if (startDateMonth < todayMonth && endDateMonth === todayMonth) {
                    categoryStarts = format(startOfMonth(todaysDate), 'D');
                    categoryEnds = format(endOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
                }
                else if (startDateMonth === todayMonth && endDateMonth > todayMonth) {
                    categoryStarts = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
                    categoryEnds = format(endOfMonth(todaysDate), 'D');
                }
                else {
                    categoryStarts = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
                    categoryEnds = format(endOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
                }
            }
            else if (_widgetScope.dateFilterValue.value === "Quarter") {
                categoryStarts = format(startOfQuarter(todaysDate), 'MMMM');
                categoryEnds = format(endOfQuarter(todaysDate), 'MMMM');
            }
            return {
                chartData: {
                    data: dataSet,
                    setMaxPrecision: true,
                    categoryStarts: categoryStarts,
                    categoryEnds: categoryEnds
                }
            };

        }
    },
    topTenSoldProducts: {
        transformResponse: ({ _clientResponse, _widgetScope }) => {
            // Set Columns
            let column = [
                {
                    "title": "#",
                    "key": "",
                    "sort": false,
                    "width": "4%",
                    "type": "AutoIncrement"
                },
                {
                    "title": "",
                    "key": "color",
                    "sort": false,
                    "type": "Color",
                    "width": "8%",
                    "textAlign": "center"
                },
                {
                    "title": "Product",
                    "key": "productID",
                    "sort": true,
                    "width": "64%",
                    "textAlign": "left"
                },
                {
                    "title": "Trend",
                    "key": "trend",
                    "sort": false,
                    "width": "12%",
                    "type": "Image",
                    "textAlign": "center"
                },
                {
                    "title": "Count",
                    "key": "counts",
                    "sort": true,
                    "width": "12%",
                    "textAlign": "center"
                }
            ]
            if (_widgetScope?.dateFilterValue?.value === "Custom") {
                column = column.filter(data => data.key !== "trend")
            }
            // Define product colors (replace with your actual colors)
            const productColors = ["#ff97cd", "#0083b3", "#7192f1", "#c10b6d", "#414548", "#eacd61", "#59a9ed", "#00eabf", "#ff3b84", "#e08e53"];

            const current = _clientResponse?.data?.data?.current?.length ? _clientResponse.data.data.current : [];
            // Transform the data
            const transformedData = current.map((currentItem, indx) => {
                // Get color based on product
                const color = productColors[indx]; // Default to black if color not found

                // Find corresponding item in previous array (if available)
                const previousItem = _clientResponse?.data?.data?.previous?.find((prevItem) => prevItem.product === currentItem.product) || 0;

                // Calculate trend
                const trend = previousItem ? (currentItem.count === previousItem.count ? 0 : currentItem.count > previousItem.count ? 1 : -1) : 0;

                // Create the new object
                return {
                    productID: currentItem.product,
                    counts: currentItem.count,
                    trend: trendArrowImages[trend === 1 ? "high" : trend === -1 ? "low" : ""],
                    color,
                    order: currentItem.count
                };
            });
            const sortedTransformData = transformedData.sort((a, b) => a.counts - b);
            return {
                data: {
                    value: sortedTransformData,
                    columns: column
                }
            }
        }
    },

    numberOfProductsSold: {
        transformResponse: async ({ _clientResponse, _widgetScope }) => {

            // Set Columns
            const columns = await getColumns(_widgetScope);

            // Set Data
            const products = await getProducts(_clientResponse, _widgetScope, columns)
            return {
                data: {
                    products,
                    columns
                }
            }
        }
    }

}

const getColumns = async (_widgetScope) => {
    const start = [{
        "title": "KC0169",
        "type": "AutoIncrement",
        "textAlign": "center"
    },
    {
        "title": "KC0170",
        "key": "product",
        "width": "50%"
    }]
    const end = [{
        "title": "KC0171",
        "key": "trend",
        "type": "Image",
        "textAlign": "center"
    },
    {
        "title": "KC0172",
        "key": "count",
        "textAlign": "center"
    }]
    let middle = []
    if (_widgetScope?.dateFilterValue?.value === 'Day') {
        middle = [{
            "title": "Day1",
            "key": "day1",
            "textAlign": "center"
        }]
    } else if (_widgetScope?.dateFilterValue?.value === 'Week') {
        middle = [{
            "title": "Day1",
            "key": "day1",
            "textAlign": "center"
        }, {
            "title": "Day2",
            "key": "day2",
            "textAlign": "center"
        }, {
            "title": "Day3",
            "key": "day3",
            "textAlign": "center"
        }, {
            "title": "Day4",
            "key": "day4",
            "textAlign": "center"
        }, {
            "title": "Day5",
            "key": "day5",
            "textAlign": "center"
        }, {
            "title": "Day6",
            "key": "day6",
            "textAlign": "center"
        }, {
            "title": "Day7",
            "key": "day7",
            "textAlign": "center"
        }
        ]
    } else if (_widgetScope?.dateFilterValue?.value === 'Month') {
        middle = [{
            "title": "Week1",
            "key": "week1",
            "textAlign": "center"
        }, {
            "title": "Week2",
            "key": "week2",
            "textAlign": "center"
        }, {
            "title": "Week3",
            "key": "week3",
            "textAlign": "center"
        }, {
            "title": "Week4",
            "key": "week4",
            "textAlign": "center"
        }, {
            "title": "Week5",
            "key": "week5",
            "textAlign": "center"
        }, {
            "title": "Week6",
            "key": "week6",
            "textAlign": "center"
        }]
    } else if (_widgetScope?.dateFilterValue?.value === 'Quarter') {
        middle = [{
            "title": "Month1",
            "key": "month1",
            "textAlign": "center"
        }, {
            "title": "Month2",
            "key": "month2",
            "textAlign": "center"
        }, {
            "title": "Month3",
            "key": "month3",
            "textAlign": "center"
        }]
    } else if (_widgetScope?.dateFilterValue?.value === 'Year') {
        middle = [{
            "title": "Q1",
            "key": "q1",
            "textAlign": "center"
        }, {
            "title": "Q2",
            "key": "q2",
            "textAlign": "center"
        }, {
            "title": "Q3",
            "key": "q3",
            "textAlign": "center"
        }, {
            "title": "Q4",
            "key": "q4",
            "textAlign": "center"
        }]
    }
    const columns = [...start, ...middle, ...end]
    return columns
}

const getProducts = async (_clientResponse, _widgetScope, columns) => {

    let data = _clientResponse?.data?.data?.products
    let previous = _clientResponse?.data?.data?.previous

    let result = []

    if (data.length) {

        // Group By Product
        let groupData = _.groupBy(data, 'product')

        if (_widgetScope?.dateFilterValue?.value === "Day") {
            for (let i = 0; i < Object.keys(groupData).length; i++) {
                let oneData = groupData[Object.keys(groupData)[i]]
                let prevCount = previous.find(prevData => prevData.product === oneData[0].product)
                let trend = prevCount?.count ? (oneData[0].count === prevCount.count ? 0 : oneData[0].count > prevCount.count ? 1 : -1) : 0;
                let oneResult = {
                    product: oneData[0].product,
                    day1: oneData[0].count,
                    count: oneData[0].count,
                    trend: trendArrowImages[trend === 1 ? "high" : trend === -1 ? "low" : ""]
                }
                result.push(oneResult)
            }
        }
        if (_widgetScope?.dateFilterValue?.value === "Week") {

            for (let i = 0; i < Object.keys(groupData).length; i++) {
                let oneData = groupData[Object.keys(groupData)[i]]
                let oneResult = {
                    product: oneData[0].product,
                    count: 0
                }
                const weeks = Array(7).fill({})
                let weekDate = moment(_widgetScope.dateFilterValueFormatted.fromDate)
                weeks.forEach(async (w, index) => {
                    let count = oneData.find(check => moment(check.date).isSame(weekDate))
                    count = count?.count || 0
                    oneResult['day' + (index + 1)] = count
                    oneResult.count = oneResult.count + count
                    weekDate = moment(weekDate).add(1, 'day')
                })
                let prevCount = previous.find(prevData => prevData.product === oneData[0].product)
                let trend = prevCount?.count ? (oneResult.count === prevCount.count ? 0 : oneResult.count > prevCount.count ? 1 : -1) : 0;
                oneResult.trend = trendArrowImages[trend === 1 ? "high" : trend === -1 ? "low" : ""]
                result.push(oneResult)
            }
        }
        if (_widgetScope?.dateFilterValue?.value === "Month") {
            for (let i = 0; i < Object.keys(groupData).length; i++) {
                let oneData = groupData[Object.keys(groupData)[i]]
                let oneResult = {
                    product: oneData[0].product,
                    count: 0
                }
                const weeks = Array(6).fill({})
                let weekNumber = moment(_widgetScope.dateFilterValueFormatted.fromDate).week()
                weeks.forEach(async (w, index) => {
                    let count = oneData.find(check => moment(check.date).week() == weekNumber)
                    count = count?.count || 0
                    oneResult['week' + (index + 1)] = count
                    oneResult.count = oneResult.count + count
                    weekNumber = weekNumber + 1
                })
                let prevCount = previous.find(prevData => prevData.product === oneData[0].product)
                let trend = prevCount?.count ? (oneResult.count === prevCount.count ? 0 : oneResult.count > prevCount.count ? 1 : -1) : 0;
                oneResult.trend = trendArrowImages[trend === 1 ? "high" : trend === -1 ? "low" : ""]
                result.push(oneResult)
            }
        }
        if (_widgetScope?.dateFilterValue?.value === "Quarter") {
            for (let i = 0; i < Object.keys(groupData).length; i++) {
                let oneData = groupData[Object.keys(groupData)[i]]
                let oneResult = {
                    product: oneData[0].product,
                    count: 0
                }
                const months = Array(3).fill({})
                let monthValue = moment(_widgetScope.dateFilterValueFormatted.fromDate).month()
                months.forEach(async (m, index) => {
                    let count = oneData.find(check => moment(check.date).month() === monthValue)
                    count = count?.count || 0
                    oneResult['month' + (index + 1)] = count
                    oneResult.count = oneResult.count + count
                    monthValue = monthValue + 1
                })
                let prevCount = previous.find(prevData => prevData.product === oneData[0].product)
                let trend = prevCount?.count ? (oneResult.count === prevCount.count ? 0 : oneResult.count > prevCount.count ? 1 : -1) : 0;
                oneResult.trend = trendArrowImages[trend === 1 ? "high" : trend === -1 ? "low" : ""]
                result.push(oneResult)
            }
        }
        if (_widgetScope?.dateFilterValue?.value === "Year") {
            for (let i = 0; i < Object.keys(groupData).length; i++) {
                let oneData = groupData[Object.keys(groupData)[i]]
                let oneResult = {
                    product: oneData[0].product,
                    count: 0
                }
                const quarters = [[1, 2, 3], [4, 5, 6], [7, 8, 9], [10, 11, 12]]
                quarters.forEach(async (m, index) => {
                    let count = oneData.find(check => m.includes(moment(check.date).month()))
                    count = count?.count || 0
                    oneResult['q' + (index + 1)] = count
                    oneResult.count = oneResult.count + count
                })
                let prevCount = previous.find(prevData => prevData.product === oneData[0].product)
                let trend = prevCount?.count ? (oneResult.count === prevCount.count ? 0 : oneResult.count > prevCount.count ? 1 : -1) : 0;
                oneResult.trend = trendArrowImages[trend === 1 ? "high" : trend === -1 ? "low" : ""]
                result.push(oneResult)
            }
        }
    }
    if (!data.length && !previous.length) return []
    return result
}

export default clientFunctions;